import s_1 from "../../images/landingpage/s_1.png";
import s_2 from "../../images/landingpage/s_2.png";
import s_3 from "../../images/landingpage/s_3.png";
import s_4 from "../../images/landingpage/s_4.png";
import s_5 from "../../images/landingpage/s_5.png";
import s_6 from "../../images/landingpage/s_6.png";
import kickbox from "../../images/landingpage/gameplay_photo_resized.png";



const slides = [
    {
      text: "Roost is a card game about strategy, memorisation, alliances and most importantly birds. Avoid being bottom of the pecking order, stay aligned with the flock and fly away to overall victory.",
      imageUrl: s_2,
      title: "Roost",
      link: "/shop",
      button_text: "Get it",
      bold_text: "Here",
    },
    {
      text: "Roost comes with 112 Playing Cards, 12 Wooden Markers, 1 Wooden Direction Token, 1 Rule Book and 1 Fabric Storage Bag.",
      imageUrl: s_2,
      title: "Contents",
    },
    {
      text: "Roost on average takes 20-60 minutes to play, and can house 2-6 players.",
      imageUrl: kickbox,
      title: "Playtime",
    },
    {
      text: "You begin with knowledge of only half of your hand. The birds you possess contain abilities to switch, steal, discover, enact revenge, boost your final score, or flip cards.",
      imageUrl: s_4,
      title: "Rules",
      link: "/rules",
      button_text: "Check out the",
      bold_text: "Rules",
    },
    {
      text: "Strive for the lowest score by skillfully matching cards with the discard pile or on top of other player's flipped cards. Beware, The roost has room for one and mistakes are not tolerated.",
      imageUrl: s_3,
      title: "Winning",
    },
  ];


  export {slides}